import React, { useState, createContext } from "react";
import { url } from "../variables";

export const DayContext = createContext();

export const DayContextProvider = (props) => {
  const [days, setDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [message, setMessage] = useState(null);
  const [name, setName] = useState("");
  // const url = window.location.origin == "http://localhost:3000" ? window.location.origin.slice(0,-4) + "3006" : window.location.origin;

  const addDayReport = (day) => {
    setDays([...days, day]);
  };
  return (
    <DayContext.Provider
      value={{
        days,
        setDays,
        addDayReport,
        selectedDay,
        setSelectedDay,
        message,
        setMessage,
        name,
        setName,
        url,
      }}
    >
      {props.children}
    </DayContext.Provider>
  );
};
